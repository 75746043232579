<template>
  <v-app>
    <core-app-bar />

    <core-drawer />

    <core-jumbotron />

    <core-view />

    <core-footer />
  </v-app>
</template>

<script>
export default {
  metaInfo: {
    titleTemplate: '%s — Majostugan',
  },

  components: {
    CoreDrawer: () => import('@/components/core/Drawer.vue'),
    CoreFooter: () => import('@/components/core/Footer.vue'),
    CoreJumbotron: () => import('@/components/core/Jumbotron.vue'),
    CoreAppBar: () => import('@/components/core/AppBar.vue'),
    CoreView: () => import('@/components/core/View.vue'),
  },
};
</script>
