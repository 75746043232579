export default {
  en: {
    name: 'English',
    load: () => import('./en.json'),
  },
  se: {
    name: 'Svenska',
    load: () => import('./se.json'),
  },
};
