import Vue from 'vue';
import './plugins/meta';
import VueGtag from 'vue-gtag';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { i18n } from 'vue-lang-router';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.use(VueGtag, {
  config: { id: 'G-XLWTR7R0LT' },
});

Vue.config.productionTip = false;

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: 'AIzaSyDdHUYzs0UGM9GA-K6cAFh9x72L-ap6Cto',

  authDomain: 'majosugan.firebaseapp.com',

  projectId: 'majosugan',

  storageBucket: 'majosugan.appspot.com',

  messagingSenderId: '505763434632',

  appId: '1:505763434632:web:3b70dd535798a69d3e4f3d',

  measurementId: 'G-6TNX9TG97M',
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

Vue.prototype.$analytics = analytics;

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
