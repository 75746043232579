import Vue from 'vue';
import LangRouter from 'vue-lang-router';
import translations from '../lang/translations';
import localizedURLs from '../lang/localized-urls';

Vue.use(LangRouter, {
  defaultLanguage: 'se',
  translations,
  localizedURLs,
});

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/stugan',
    name: 'Stugan',
    component: () => import('@/views/Stugan.vue'),
  },
  {
    path: '/trivsel',
    name: 'Trivsel',
    component: () => import('@/views/Trivsel.vue'),
  },
  {
    path: '/activities',
    name: 'Activities',
    component: () => import('@/views/Activities.vue'),
  },
];

const router = new LangRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
